import { Spinner } from "@shared";
import clsx from "clsx";

type Props = {
  tableHeightPx: number;
  isCalculating: boolean;
  studentPanelWidth: number;
  weekdayPanelWidth: number;
};

export const TrackerIsCalculatingOverlay = ({
  tableHeightPx,
  isCalculating,
  studentPanelWidth,
  weekdayPanelWidth,
}: Props) => {
  const renderIsCalculatingPanel = (
    <>
      <div
        className={clsx(
          "absolute left-px w-[calc(100%-2px)] h-full",
          "bg-white/50 z-1000 rounded-md backdrop-blur-xs"
        )}
      />
      <div className="absolute top-[30px] left-0 w-full justify-center items-end flex z-1001 h-[30%]">
        <Spinner color="text-blue-800" size={8} />
      </div>
    </>
  );

  return isCalculating ? (
    <div
      className={clsx(
        "absolute top-[2px] left-0 w-full flex justify-between",
        "px-px pointer-events-none"
      )}
      style={{ height: `${tableHeightPx - 4}px` }}
    >
      <div
        className="relative h-full"
        style={{ width: `${studentPanelWidth - 2}px` }}
      >
        {renderIsCalculatingPanel}
      </div>

      <div
        className="relative h-full"
        style={{ width: `${weekdayPanelWidth - 2}px` }}
      >
        {renderIsCalculatingPanel}
      </div>
    </div>
  ) : null;
};
