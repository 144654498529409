import { startOfDay } from "date-fns";
import dayjs from "dayjs";

export const disabledDay = (
  day: dayjs.Dayjs,
  boundedStart: Date,
  boundedEnd: Date
) => {
  const dayStart = startOfDay(day.toDate()).getTime();
  return dayStart < boundedStart.getTime() || dayStart >= boundedEnd.getTime();
};
