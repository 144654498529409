type Props = {
  isScrolledUp: boolean;
  isScrolledDown: boolean;
  isBodyOverflowing: boolean;
  studentPanelWidth: number;
  weekdayPanelWidth: number;
};

export const TrackerHorizontalAbsoluteBorders = ({
  isScrolledUp,
  isScrolledDown,
  isBodyOverflowing,
  studentPanelWidth,
  weekdayPanelWidth,
}: Props) =>
  isBodyOverflowing ? (
    <>
      <div className="absolute top-0 left-0 flex h-px w-full">
        {isScrolledDown && (
          <div className="flex justify-between h-full w-full">
            {/* Left Panel | Top Border */}
            <div
              className="h-full bg-gray-300 ml-px"
              style={{ width: `${studentPanelWidth - 2}px` }}
            />
            {/* Right Panel | Top Border */}
            <div
              className="h-full bg-gray-300"
              style={{ width: `${weekdayPanelWidth - 2}px` }}
            />
          </div>
        )}
      </div>

      <div className="absolute bottom-0 left-0 flex h-px w-full">
        {isScrolledUp && (
          <div className="flex justify-between h-full w-full">
            {/* Left Panel | Bottom Border */}
            <div
              className="h-full bg-gray-300 ml-px"
              style={{ width: `${studentPanelWidth - 2}px` }}
            />
            {/* Right Panel | Bottom Border */}
            <div
              className="h-full bg-gray-300"
              style={{ width: `${weekdayPanelWidth - 2}px` }}
            />
          </div>
        )}
      </div>
    </>
  ) : null;
