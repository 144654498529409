import { clsx } from "clsx";

type Props = {
  isScrolledRight: boolean;
  isScrolledLeft: boolean;
  isBodyOverflowing: boolean;
};

export const TrackerVerticalAbsoluteBorders = ({
  isScrolledRight,
  isScrolledLeft,
  isBodyOverflowing,
}: Props) =>
  isBodyOverflowing ? (
    <>
      <div
        className={clsx(
          "absolute top-px left-0 flex h-[calc(100%-2px)] w-px",
          isScrolledRight ? "bg-gray-300" : "bg-none"
        )}
      />
      <div
        className={clsx(
          "absolute top-px right-0 flex h-[calc(100%-2px)] w-px",
          isScrolledLeft ? "bg-gray-300" : "bg-none"
        )}
      />
    </>
  ) : null;
