import { HolidayType } from "@generated/graphql";
import { Icon, Tooltip } from "@shared";
import { IsEngagementExclusionDays } from "@shared/AttendanceGrades/types";
import { IconType } from "@shared/Icon";
import { Fragment, ReactNode } from "react";

type Props = {
  attendance?: boolean;
  specialDays: IsEngagementExclusionDays;
  isBeforeStudentStart: boolean;
  isAfterStudentRemoved: boolean;
};

export const WeekdayEngagementExclusionIcons = ({
  attendance = true,
  specialDays: {
    isEngagementNoShowDay,
    isEngagementAssessmentDay,
    isEngagementNoTutoringDay,
  },
  isBeforeStudentStart,
  isAfterStudentRemoved,
}: Props) => {
  const icons: React.ReactNode[] = [];

  const reasonWrap = (reason: string, holiday?: string) => (
    <div className="flex-center text-center flex-col">
      {`${holiday ?? "Engagement"} ${reason}`}
      <p>{`No ${attendance ? "Attendance" : "Grades"}`}</p>
    </div>
  );

  const renderIcon = (reason: ReactNode, icon: IconType) => (
    <Tooltip
      key={`${reason}-${icon}`}
      content={reason}
      contentClassName="text-sm text-center max-w-[26ch]"
    >
      <Icon icon={icon} size={4} color={"text-gray-600"} />
    </Tooltip>
  );

  if (isEngagementNoShowDay)
    icons.push(renderIcon(reasonWrap("No-Show Day"), "noShow"));
  if (isEngagementAssessmentDay)
    icons.push(renderIcon(reasonWrap("Assessment Day"), "assessment"));
  if (isEngagementNoTutoringDay.length > 0) {
    isEngagementNoTutoringDay.map(({ name, type }) => {
      const isHoliday = type === HolidayType.Usa;
      icons.push(
        renderIcon(
          reasonWrap(
            isHoliday ? `(${name})` : "Engagement No-Tutoring Day",
            isHoliday ? "US Holiday" : undefined
          ),
          "palmTree"
        )
      );
    });
  }
  if (isBeforeStudentStart || isAfterStudentRemoved)
    icons.push(
      renderIcon(
        isBeforeStudentStart
          ? "Student has not yet been added to any cohorts within this engagement"
          : "Student is no longer apart of any cohorts within this engagement.",
        "disabled"
      )
    );

  return (
    <div className="flex gap-x-[3px] items-center">
      {icons.map((icon, i) => (
        <Fragment key={i}>{icon}</Fragment>
      ))}
    </div>
  );
};
