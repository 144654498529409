import { HolidayType } from "@generated/graphql";
import { Icon, Tooltip } from "@shared";
import { IsEngagementExclusionDays } from "@shared/AttendanceGrades/types";
import { IconType } from "@shared/Icon";
import { uniqBy } from "lodash";

type Props = {
  currentDay: boolean;
  attendance?: boolean;
  organizationName: string;
  specialDays: IsEngagementExclusionDays;
};

export const WeekdayHeaderIcons = ({
  currentDay,
  organizationName,
  attendance = true,
  specialDays: {
    isEngagementNoShowDay,
    isEngagementAssessmentDay,
    isEngagementNoTutoringDay,
  },
}: Props) => {
  const icons: React.ReactNode[] = [];

  const renderIcon = (reason: string, icon: IconType) => (
    <Tooltip
      key={`${reason}-${icon}`}
      content={
        <div className="flex-center text-center flex-col">
          {reason}
          <p>{`No ${attendance ? "Attendance" : "Grades"}`}</p>
        </div>
      }
      contentClassName="text-sm text-center max-w-[26ch]"
    >
      <Icon
        icon={icon}
        color={
          currentDay
            ? "text-white"
            : icon == "assessment"
            ? "text-fuchsia-600"
            : "text-purple-600"
        }
        size={icon == "assessment" ? 3 : 4}
      />
    </Tooltip>
  );

  if (isEngagementNoShowDay)
    icons.push(renderIcon("Engagement No-Show Day", "noShow"));
  if (isEngagementAssessmentDay)
    icons.push(renderIcon("Engagement Assessment Day", "assessment"));
  if (isEngagementNoTutoringDay.length > 0) {
    icons.push(
      <Tooltip
        content={
          <div className="flex flex-col text-xs font-medium text-white text-center max-w-[20ch]">
            <div className="flex flex-col gap-y-1">
              {uniqBy(isEngagementNoTutoringDay, "name").map(
                ({ name, type }) => (
                  <div key={name} className="flex flex-col gap-y-0">
                    <p>{name}</p>
                    <p className="italic">
                      (
                      {type === HolidayType.Usa
                        ? "US Holiday"
                        : `${
                            organizationName ?? "Organization"
                          } no tutoring day`}
                      )
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
        }
        tooltipProps={{ place: "bottom" }}
      >
        <Icon
          icon="palmTree"
          color={currentDay ? "text-white" : "text-emerald-600"}
          size={4}
        />
      </Tooltip>
    );
  }

  return icons.length === 0 ? null : (
    <div className="flex gap-1 items-center">{...icons}</div>
  );
};
