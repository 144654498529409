import {
  SessionReportEvaluationTableDetails,
  SessionReportStudentEvaluationRow,
} from "@shared/SessionReports/types";
import { getScrollbarStyle } from "@utils/styleStrings";
import { isLiveParticipantsVideoProvider } from "@utils/videoProviders";
import { clsx } from "clsx";
import { useMemo } from "react";
import { SessionStudentEvaluationTableHeader } from "./components/SessionStudentEvaluationTableHeader";
import { SessionStudentEvaluationTableRow } from "./components/SessionStudentEvaluationTableRow/SessionStudentEvaluationTableRow";
import {
  ATTENDANCE_MIN_WIDTH,
  ATTENDANCE_READONLY_WIDTH,
  BADGE_ATTENDANCE_WIDTH,
  BADGE_GRADING_WIDTH,
  GRADING_MIN_WIDTH,
  GRADING_READONLY_WIDTH,
} from "./constants";

type Props = {
  readOnly?: boolean;
  highlightIncomplete?: boolean;
  evaluationDetails: SessionReportEvaluationTableDetails;
  studentEvaluationRows: SessionReportStudentEvaluationRow[];
};

export function SessionStudentEvaluationTable({
  readOnly = false,
  evaluationDetails,
  highlightIncomplete,
  studentEvaluationRows,
}: Props) {
  const { attendanceColWidth, gradesColWidth } = useMemo(() => {
    const hasAttendance = studentEvaluationRows.some((row) => !!row.attendance);
    const hasGrades = studentEvaluationRows.some((row) => !!row.grading);

    return {
      attendanceColWidth: readOnly
        ? ATTENDANCE_READONLY_WIDTH
        : hasAttendance
        ? ATTENDANCE_MIN_WIDTH
        : BADGE_ATTENDANCE_WIDTH,
      gradesColWidth: readOnly
        ? GRADING_READONLY_WIDTH
        : hasGrades
        ? GRADING_MIN_WIDTH
        : BADGE_GRADING_WIDTH,
    };
  }, [readOnly, studentEvaluationRows]);

  const liveParticipantsVideoProvider = isLiveParticipantsVideoProvider(
    evaluationDetails.videoProvider
  );

  return (
    <div
      className={clsx(
        "block w-full h-full overflow-x-auto",
        getScrollbarStyle("barOnly")
      )}
    >
      <div
        className={clsx(
          "flex flex-col min-w-full w-fit border rounded-lg",
          "border-gray-300 divide-y divide-gray-300"
        )}
      >
        <SessionStudentEvaluationTableHeader
          readOnly={readOnly}
          showScreenName={liveParticipantsVideoProvider}
          gradesColWidth={gradesColWidth}
          attendanceColWidth={attendanceColWidth}
          isGradingEnabled={evaluationDetails.isGradingEnabled}
        />

        {studentEvaluationRows.map((row, i) => (
          <SessionStudentEvaluationTableRow
            key={row.studentId}
            readOnly={readOnly}
            studentEvaluationRow={row}
            gradesColWidth={gradesColWidth}
            evaluationDetails={evaluationDetails}
            attendanceColWidth={attendanceColWidth}
            zIndex={studentEvaluationRows.length - i}
            highlightIncomplete={highlightIncomplete}
            showScreenName={liveParticipantsVideoProvider}
            className={clsx(
              i == studentEvaluationRows.length - 1 && "rounded-b-lg"
            )}
          />
        ))}
      </div>
    </div>
  );
}
