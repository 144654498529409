import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import clsx from "clsx";
import { subWeeks } from "date-fns";

type Props = {
  weekViewDate: Date;
  direction: "left" | "right";
  setWeekViewDate: (date: Date) => void;
};

export const TrackerWeekNavButton = ({
  direction,
  weekViewDate,
  setWeekViewDate,
}: Props) => {
  const isLeft = direction === "left";

  const handleClick = () =>
    setWeekViewDate(subWeeks(weekViewDate, isLeft ? 1 : -1));

  return (
    <button
      type="button"
      autoFocus={false}
      onClick={handleClick}
      className={clsx(
        "hidden sm:flex border border-gray-400 absolute flex-center top-[13px]",
        "w-6 rounded-md hover:text-blue-900 hover:bg-slate-300/50 px-1 z-2000",
        "text-gray-500 bg-blue-800/[0.05] cursor-pointer!",
        isLeft ? "left-[7px]" : "right-[7px]"
      )}
    >
      {isLeft ? (
        <ChevronLeftIcon
          className="h-12 w-12 hover:scale-[1.5]"
          aria-hidden="true"
        />
      ) : (
        <ChevronRightIcon
          className="h-12 w-12 hover:scale-[1.5]"
          aria-hidden="true"
        />
      )}
    </button>
  );
};
