import { Icon } from "@shared";
import { Indicator } from "./Indicator";

type Props = { completed: boolean };

export const FingerprintingIndicator = ({ completed }: Props) => (
  <Indicator
    tooltip={`Fingerprinting ${completed ? "C" : "Inc"}omplete`}
    className={completed ? "bg-emerald-500" : "bg-rose-500"}
  >
    <Icon icon="fingerprint" color="text-white" size={4} />
  </Indicator>
);
