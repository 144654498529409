import { Button, Icon, SelectMenu, SelectMenuOption, Tooltip } from "@shared";

type Props = {
  label: string;
  tooltip: string;
  initialIndex?: number;
  options: SelectMenuOption[];
  setFilter: (filter: string | null) => void;
};

export const TrackerHeaderFilter = ({
  label,
  tooltip,
  options,
  initialIndex,
  setFilter,
}: Props) => (
  <>
    <Tooltip
      tooltipProps={{ place: "right" }}
      className="flex cursor-pointer w-full h-[30px]! items-center justify-between"
      content={tooltip}
    >
      <label>{label}</label>
      <Icon icon="info" size={5} />
    </Tooltip>

    <SelectMenu
      heightPx={30}
      options={options ?? []}
      initialIndex={initialIndex}
      onSelect={({ id }) => setFilter(id === "" ? null : id)}
    />

    <div className="h-full flex items-center">
      <Button
        theme="tertiary"
        height="xs"
        className="px-3 w-fit"
        onClick={() => setFilter(null)}
      >
        Clear
      </Button>
    </div>
  </>
);
