import { Indicator } from "./Indicator";

export const EnglishLanguageLearnerSupportIndicator = () => (
  <Indicator
    tooltip="Comfortable supporting English language learners"
    className="bg-orange-300 text-orange-700 text-[9px] tracking-tighter"
  >
    ELL
  </Indicator>
);
