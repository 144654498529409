import { CohortAssignmentSubject, EngagementStatus } from "@generated/graphql";
import { ArchivedBadge, TestingUseOnlyBadge } from "@shared";
import { getSubjectText } from "@utils/labels";
import { getNormalizedShortFullDateRangeText } from "helpers";

type Props = {
  publicPage: boolean;
  engagementName: string;
  engagementEndDate: Date;
  organizationName: string;
  engagementStartDate: Date;
  isTestOrganization: boolean;
  downloadButton?: React.ReactNode;
  subject?: CohortAssignmentSubject;
  engagementStatus: EngagementStatus;
};

export const TrackerHeaderDetails = ({
  subject,
  publicPage,
  downloadButton,
  engagementName,
  engagementStatus,
  organizationName,
  engagementEndDate,
  isTestOrganization,
  engagementStartDate,
}: Props) => {
  const isAttendanceTracker = !subject;

  return (
    <div className="flex flex-col -mt-[4px] gap-y-px">
      <div className="flex items-center gap-x-[6px] text-[26px] font-bold leading-none text-gray-800 sm:truncate">
        {isAttendanceTracker
          ? "Student Attendance"
          : `${getSubjectText(subject)} Gradebook`}

        {!publicPage && engagementStatus === EngagementStatus.Archived && (
          <ArchivedBadge className="text-sm -mb-px" />
        )}

        {!publicPage && isTestOrganization && (
          <TestingUseOnlyBadge className="text-sm -mb-px" />
        )}

        {downloadButton}
      </div>
      <div className="flex flex-row items-center gap-x-2 font-semibold text-gray-600 my-px text-[13px] leading-none">
        <span>{`${organizationName} | ${engagementName}`}</span>
      </div>
      <div className="flex flex-row gap-x-2 text-[11px] mt-[1px] leading-none font-bold text-gray-800">
        {getNormalizedShortFullDateRangeText(
          new Date(engagementStartDate).getTime(),
          new Date(engagementEndDate).getTime()
        )}
      </div>
    </div>
  );
};
