import { Indicator } from "./Indicator";

export const SpanishFluencyIndicator = () => (
  <Indicator
    tooltip="Fluent in Spanish"
    className="bg-purple-300 text-purple-900 text-[9px]"
  >
    SP
  </Indicator>
);
