import { CohortSessionStudentAttendanceStatus } from "@generated/graphql";

export const HEADER_OFFSET = 480;
export const MIN_ATTENDANCE_WEEKDAY_WIDTH = 110;

export const AttendanceStatusColors = {
  [CohortSessionStudentAttendanceStatus.Unknown]: "bg-gray-500",
  [CohortSessionStudentAttendanceStatus.Present]: "bg-emerald-500",
  [CohortSessionStudentAttendanceStatus.Absent]: "bg-rose-500",
  [CohortSessionStudentAttendanceStatus.Partial]: "bg-amber-500",
  notHeld: "bg-purple-500",
};
