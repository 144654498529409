import { Button, Icon, Tooltip } from "@shared";
import { DatePickerInput } from "@shared/DatePickerInput";
import { getShortInputStyle } from "@utils/styleStrings";
import { endOfDay, startOfDay } from "date-fns";
import dayjs from "dayjs";
import { disabledDay } from "../../../../sections/Engagements/components/EngagementStudentAttendancePage/components/StudentAttendanceNavFiltersHeader/utils";

type Props = {
  endDateBound: Date;
  startDateBound: Date;
  endDateFilter: Date | null;
  startDateFilter: Date | null;
  setFilters: (
    startDateFilter: Date | null,
    endDateFilter: Date | null
  ) => void;
};

export const TrackerDateRangeFilter = ({
  endDateBound,
  endDateFilter,
  startDateBound,
  startDateFilter,
  setFilters,
}: Props) => (
  <>
    <Tooltip
      tooltipProps={{ place: "right" }}
      className="flex w-full items-center h-[30px]! justify-between"
      content={
        <div className="flex flex-col items-center max-w-[250px] text-center gap-y-2">
          <span>
            Customize the date range you would like to see attendance data for.
          </span>
        </div>
      }
    >
      <label>Date</label>
      <Icon icon="info" size={5} />
    </Tooltip>

    <div className="flex items-center gap-x-1">
      <DatePickerInput
        allowClear
        placeholder="Start"
        className="flex flex-1"
        style={getShortInputStyle}
        datepickerClassName="h-[30px]!"
        defaultPickerValue={dayjs(startDateBound)}
        value={startDateFilter ? dayjs(startDateFilter) : null}
        disabledDate={(date) =>
          disabledDay(date, startDateBound, endDateFilter ?? endDateBound)
        }
        onChange={(date) =>
          setFilters(date ? startOfDay(date.toDate()) : null, endDateFilter)
        }
      />

      <DatePickerInput
        allowClear
        placeholder="End"
        className="flex flex-1"
        style={getShortInputStyle}
        datepickerClassName="h-[30px]!"
        defaultPickerValue={dayjs(endDateBound)}
        value={endDateFilter ? dayjs(endDateFilter) : null}
        disabledDate={(date) =>
          disabledDay(date, startDateFilter ?? startDateBound, endDateBound)
        }
        onChange={(date) =>
          setFilters(startDateFilter, date ? endOfDay(date.toDate()) : null)
        }
      />
    </div>

    <div className="h-full flex items-center">
      <Button
        height="xs"
        theme="tertiary"
        className="px-3 w-fit"
        onClick={() => setFilters(null, null)}
      >
        Clear
      </Button>
    </div>
  </>
);
