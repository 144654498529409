import { Icon } from "@shared/Icon";
import clsx from "clsx";
import { Indicator } from "./Indicator";

type Props = {
  disableTooltip?: boolean; // For Toggle Indicator
  isTeacherIndicator?: boolean; // For Teacher || JobBoard
};

export const UsaResidentIndicator = ({
  isTeacherIndicator,
  disableTooltip = false,
}: Props) => (
  <Indicator
    size={disableTooltip ? 4 : 5}
    disableTooltip={disableTooltip}
    className="overflow-hidden relative justify-start! items-start!"
    tooltip={
      <div className="text-sm font-semibold text-center w-fit-content text-white">
        {!!isTeacherIndicator ? "For US Residents Only" : "Is USA Resident"}
      </div>
    }
  >
    <Icon
      size={3}
      icon="stars"
      color={"text-white"}
      className="z-100 ml-px mt-px"
    />
    <div className="absolute top-0 left-0 w-full h-full z-0 bg-white">
      {[...new Array(13)].map((_, i) => (
        <div
          key={i}
          className={clsx("h-1/9", i % 2 === 0 ? "bg-red-500" : "bg-white")}
        />
      ))}
    </div>
    <div className="absolute top-0 left-0 w-4/7 h-4/7 z-10 bg-blue-700" />
  </Indicator>
);
