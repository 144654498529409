import { useAuth } from "@contexts/AuthProvider";
import { CohortAssignmentSubject } from "@generated/graphql";
import { getSubjectText } from "@utils/labels";
import { Routes } from "@utils/routes";
import { useGradingPeriodData } from "contexts/GradingPeriodProvider";
import { Button } from "../../Buttons";
import { getSubjectAllCombo, getSubjectComboKey } from "../utils";

type Props = {
  subjects: CohortAssignmentSubject[];
  limitedAccessKey?: string;
  engagementId: string;
  publicPage: boolean;
  title: string;
};

export const SubjectButtonList = ({
  title,
  subjects,
  publicPage,
  engagementId,
  limitedAccessKey,
}: Props) => {
  const { gradingPeriodData } = useGradingPeriodData();
  const { setActiveRoute } = useAuth();
  const handleSubjectRedirect = (subject: CohortAssignmentSubject) => {
    if (publicPage) {
      setActiveRoute(Routes.engagement.publicStudentGrades.subjectSelected, [
        limitedAccessKey ?? "",
        getSubjectComboKey(getSubjectAllCombo(subject)),
        gradingPeriodData?.gradingPeriodQuery ?? "",
      ]);
    } else {
      setActiveRoute(Routes.engagement.studentGrades.subjectSelected, [
        engagementId,
        getSubjectComboKey(getSubjectAllCombo(subject)),
        gradingPeriodData?.gradingPeriodQuery ?? "",
      ]);
    }
  };

  return (
    <div className="flex flex-col gap-y-1">
      <p className="text-md font-medium text-gray-600">{title}</p>
      <div className="flex flex-row gap-2 flex-wrap">
        {subjects.map((subject) => (
          <Button
            key={subject}
            onClick={() => handleSubjectRedirect(subject)}
            height="xs"
            theme="primary"
            className="flex flex-center text-sm"
          >
            {getSubjectText(subject)}
          </Button>
        ))}
      </div>
    </div>
  );
};
