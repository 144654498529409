import { gql } from "@apollo/client";
import { AttendanceScorecardTable_TeachersFragment } from "@generated/graphql";
import { SeverityBadge } from "@shared/Badges/SeverityBadge";
import { getInfractionTypeText } from "@utils/labels";
import clsx from "clsx";
import { AccountRoleBadge } from "components/shared";
import { Table } from "components/shared/Table";
import { format } from "date-fns";
import { CellProps, Column } from "react-table";
import { AttendanceStatusColors } from "sections/Engagements/components/EngagementStudentAttendancePage";

AttendanceScorecardTable.fragments = {
  attendance: gql`
    fragment AttendanceScorecardTable_Teachers on AttendanceScorecardRecord {
      id
      type
      role
      details
      severity
      cohortName
      recordedBy
      sessionDate
      engagementName
      reviewForDestaff
      attendanceStatus
    }
  `,
};

type Props = {
  attendanceRecords: AttendanceScorecardTable_TeachersFragment[];
};

export function AttendanceScorecardTable({ attendanceRecords }: Props) {
  return (
    <Table
      className="min-h-0"
      columns={getColumns}
      showPagination={false}
      cellPadding="py-3 px-4"
      data={attendanceRecords}
    />
  );
}

const getColumns: Column<AttendanceScorecardTable_TeachersFragment>[] = [
  {
    Header: "Date",
    accessor: "sessionDate",
    Cell: ({
      row: { original },
    }: CellProps<AttendanceScorecardTable_TeachersFragment>) => (
      <div className="whitespace-nowrap">
        {format(new Date(original.sessionDate), "MMM d/yy")}
      </div>
    ),
  },
  {
    Header: "Role",
    Cell: ({
      row: { original },
    }: CellProps<AttendanceScorecardTable_TeachersFragment>) => (
      <AccountRoleBadge accountRole={original.role} mode="tiny" />
    ),
  },
  {
    Header: "Status",
    Cell: ({
      row: { original },
    }: CellProps<AttendanceScorecardTable_TeachersFragment>) => {
      if (!original.attendanceStatus) return;
      return (
        <div
          className={clsx(
            "w-3 h-3 rounded-full cursor-pointer ml-3",
            AttendanceStatusColors[original.attendanceStatus]
          )}
        />
      );
    },
  },
  {
    Header: "Engagement",
    accessor: "engagementName",
    Cell: ({
      row: { original },
    }: CellProps<AttendanceScorecardTable_TeachersFragment>) => (
      <span>{original.engagementName}</span>
    ),
  },
  {
    Header: "Cohort",
    accessor: "cohortName",
    Cell: ({
      row: { original },
    }: CellProps<AttendanceScorecardTable_TeachersFragment>) => (
      <span>{original.cohortName}</span>
    ),
  },
  {
    Header: "Severity",
    Cell: ({
      row: {
        original: { severity },
      },
    }: CellProps<AttendanceScorecardTable_TeachersFragment>) => {
      if (!severity) return;
      return <SeverityBadge severity={severity} />;
    },
  },
  {
    Header: "Type",
    width: 370,
    Cell: ({
      row: {
        original: { type },
      },
    }: CellProps<AttendanceScorecardTable_TeachersFragment>) => {
      if (!type) return;
      const typeString = getInfractionTypeText[type];
      return typeString;
    },
  },
  {
    Header: "Details",
    width: 200,
    Cell: ({
      row: { original },
    }: CellProps<AttendanceScorecardTable_TeachersFragment>) => (
      <div className="block">{original.details}</div>
    ),
  },
  {
    Header: "Destaff",
    Cell: ({
      row: { original },
    }: CellProps<AttendanceScorecardTable_TeachersFragment>) => {
      if (original.reviewForDestaff === null) return;
      return <span>{original.reviewForDestaff ? "Yes" : "No"}</span>;
    },
  },
  {
    Header: "Reported By",
    Cell: ({
      row: { original },
    }: CellProps<AttendanceScorecardTable_TeachersFragment>) => {
      if (!original.recordedBy) return;
      return <span>{original.recordedBy}</span>;
    },
  },
];
