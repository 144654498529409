import { Icon } from "@shared/Icon";
import clsx from "clsx";
import { useMemo } from "react";
import { Indicator } from "./Indicator";

type Props = {
  reverse?: boolean;
  className?: string;
  finalizeChange: boolean;
  initializeChange: boolean;
};

export const AttendanceUpdateStatusIndicator = ({
  reverse,
  finalizeChange,
  initializeChange,
  className = "text-yellow-900",
}: Props) => {
  const statusIcon = useMemo(() => {
    if (initializeChange) return reverse ? "doubleSpinner" : "keyboard";
    if (finalizeChange) return reverse ? "keyboard" : "doubleSpinner";
    return "checkmark";
  }, [reverse, finalizeChange, initializeChange]);

  return (
    <Indicator size={6} className={className}>
      {statusIcon === "keyboard" ? (
        <div className="relative">
          <Icon
            size={6}
            icon="keyboard"
            color="inherit"
            className="absolute animate-ping opacity-50"
          />
          <Icon icon="keyboard" size={6} />
        </div>
      ) : (
        <Icon
          icon={statusIcon}
          size={6}
          className={clsx({
            "animate-spin": statusIcon === "doubleSpinner",
            "opacity-80": statusIcon === "checkmark",
          })}
        />
      )}
    </Indicator>
  );
};
