import { VideoProvider } from "@generated/graphql";

const LIVE_PARTICIPANTS_SUPPORTING_VIDEO_PROVIDERS: VideoProvider[] = [
  VideoProvider.Zoom,
  VideoProvider.MicrosoftTeams,
];

export const isLiveParticipantsVideoProvider = (
  videoProvider: VideoProvider | null | undefined
) => {
  return videoProvider
    ? LIVE_PARTICIPANTS_SUPPORTING_VIDEO_PROVIDERS.includes(videoProvider)
    : false;
};
