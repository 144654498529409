import { ReactTooltipProps, Tooltip } from "@shared/Tooltip";
import { getSize } from "@utils/styleStrings";
import clsx from "clsx";

type Props = {
  size?: number;
  className?: string;
  disableTooltip?: boolean;
  tooltip?: React.ReactNode;
  children?: React.ReactNode;
  tooltipProps?: ReactTooltipProps;
};

export const Indicator = ({
  tooltip,
  size = 5,
  children,
  className,
  tooltipProps,
  disableTooltip = false,
}: Props) => (
  <Tooltip
    content={tooltip}
    tooltipProps={tooltipProps}
    disabled={!tooltip || disableTooltip}
  >
    <div
      className={clsx(
        "flex relative flex-center cursor-pointer rounded-full",
        getSize(size),
        className
      )}
    >
      {children}
    </div>
  </Tooltip>
);
