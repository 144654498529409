import { Indicator } from "./Indicator";

export const SpecialEducationCertifiedIndicator = () => (
  <Indicator
    tooltip="Special Education Certified"
    className="bg-blue-200 text-blue-700 text-[9px]"
  >
    SE
  </Indicator>
);
